<template>
  <section class="min-h-screen">
    <van-skeleton
      v-if="loading"
      class="basic-skeleton"
      :row="5"
    />
    <template v-else>
      <van-tabs
        v-model="activeTabName"
        sticky
        animated
        class="min-h-screen"
      >
        <van-tab
          title="新闻预览"
          name="basic"
          class="bg-gray-100 tab-content-container pt-2"
        >
          <Basic
            :news="news"
            module="workspace"
          />
        </van-tab>
        <van-tab
          title="管理操作"
          name="manage"
          class="bg-gray-100 tab-content-container"
        >
          <Manage :news="news" />
        </van-tab>
      </van-tabs>

      <van-empty
        v-if="!news"
        description="无法加新闻信息，请稍后再试！"
      />
    </template>
  </section>
</template>

<script>
import { mapState } from 'vuex'
import Basic from '@/components/news/basic'
import Manage from './components/manage'

import share from '@/mixins/share'
export default {
  name: 'WorkspaceIssueEdit',
  components: {
    Basic,
    Manage
  },
  mixins: [share],
  data() {
    return {
      loading: true,
      processing: false,
      news: null,
      activeTabName: 'basic'
    }
  },
  computed: {
    ...mapState('Common/Cloud', ['tcb'])
  },
  async mounted() {
    await Promise.all([
      this.fetch(),
      this.$store.dispatch('Workspace/Committee/fetch'),
      this.prepareWx()
    ])
    let options = {
      title: this.news?.title,
      desc: this.news?.subtitle,
    }
    if(this.news?.image) {
      options.imgUrl = this.news.image
    }
    this.updateShareData(options)

    if(typeof Heti != 'undefined') {
      const heti = new Heti('.heti')
      heti.autoSpacing()
    }
  },
  methods: {
    async fetch() {
      this.loading = true
      try {
        const response = await this.tcb.callFunction({
          name: 'news',
          data: {
            $url: 'workspaceGet',
            id: this.$route.params.news
          }
        })
        // eslint-disable-next-line no-console
        console.warn('获取到相关社区新闻', response)
        if (response.result?.error) {
          this.$notify({ type: 'danger', message: response.result.error })
        } else {
          let news = response.result.data
          this.$set(this, 'news', news)
        }
      } catch (err) {
        this.$notify({ type: 'danger', message: '服务器内部错误' })
        // eslint-disable-next-line no-console
        console.error('无法获取社区新闻', err)
        this.news = null
      }
      this.$nextTick(() => {
        this.loading = false
      })
    }
  }
}
</script>
<style lang="scss" scoped>
.basic-skeleton {
  padding: 30px 16px !important;
}
.tab-content-container {
  min-height: calc(100vh - 44px);
}
</style>
